import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"

const StyledTitle = styled.h6`
  width: max-content;
  font-size: 1.32em;
  padding-inline: 0.62em;
  padding-block: 0.32em;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.primary.brown};
  ${() =>
    media.sp(css`
      font-size: 1.12em;
    `)}
`

export default function InquiryType({ title, style }) {
  return <StyledTitle style={style}>{title}</StyledTitle>
}
