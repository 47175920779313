import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"

import InquiryType from "./InquiryType"

const StyledList = styled.ul`
  font-size: 0.9em;
  list-style: initial;
  margin-left: 1.5rem;
  margin-block-start: 1rem;
  padding-inline-end: 1.5rem;
  line-height: 1.4;
  width: 100%;
  & li:not(:first-child) {
    word-break: keep-all;
    overflow-wrap: break-word;
    margin-block-start: 1rem;
  }
`

export default function InquiryByEmail() {
  return (
    <>
      <InquiryType title="メールでのお問合せ" />
      <StyledList>
        <li>
          こちらは商品に関するお問合せフォームです。商品以外のお問合せについては、お手数ですが下記「会社に関するお問合せ」フォームからお問合せください。
        </li>
        <li>
          お問い合わせの受付確認について
          <br />
          以下のフォーム送信後に自動返信メールをお送りいたします。
          <br />
          ※１日経過しても届かない場合は、お手数ですがその旨をお問い合わせください。
          <br />
          （フリーダイヤル：0120-894-180）
        </li>
        <li>
          お問い合わせ内容へのご返信について
          <br />
          通常７日以内には返信いたします。
          <br />
          ※メールアドレスに誤りがある場合、またシステム障害の際には、ご返答できない場合もございます。
        </li>
        <li>
          返信が届かない場合について
          <br />
          ７日経過しても返信がない場合は、お手数ですが再度フォームにてお問い合わせください。
        </li>
        <li>
          返信内容の取り扱いについてのお願い
          <br />
          弊社からの返答はお客様個人宛てにお送りするものです。
          <br />
          返答の一部または全体の転載・二次利用はお断りいたします。
        </li>
        <li>
          セールス、勧誘等について
          <br />
          こちらのお問い合わせフォームからのセールス、勧誘等は固くお断りいたします。
          <br />
          （送信いただいても対応いたしかねます）
        </li>
      </StyledList>
      {/* <ul>
    <li>こちらは商品に関するお問合せフォームです。商品以外のお問合せについては、お手数ですが下記「会社に関するお問合せ」フォームからお問合せください。</li>
    <li>お問い合わせの受付確認について<br>以下のフォーム送信後に自動返信メールをお送りいたします。<br>※１日経過しても届かない場合は、お手数ですがその旨をお問い合わせください。<br>（フリーダイヤル：0120-894-180）</li>
    <li>お問い合わせ内容へのご返信について</br>通常７日以内には返信いたします。<br>※メールアドレスに誤りがある場合、またシステム障害の際には、ご返答できない場合もございます。</li><li>返信が届かない場合について<br>７日経過しても返信がない場合は、お手数ですが再度フォームにてお問い合わせください。</li><li>返信内容の取り扱いについてのお願い<br>弊社からの返答はお客様個人宛てにお送りするものです。<br>返答の一部または全体の転載・二次利用はお断りいたします。</li><li>セールス、勧誘等について<br>こちらのお問い合わせフォームからのセールス、勧誘等は固くお断りいたします。<br>（送信いただいても対応いたしかねます）</li>
    </ul> */}
    </>
  )
}
