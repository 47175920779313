import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"

import InquiryType from "./InquiryType"
const StyledWrapper = styled.div`
  margin-block: 50px;
  // width: max-content;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 8px;
`
const StyledFreeDial = styled.p`
  & a {
    display: flex;
    flex-wrap: no-wrap;
    align-items: flex-end;
    padding-block-start: 4px;
    ${() =>
      media.sp(css`
        margin-block-start: 8px;
      `)}
  }

  font-size: 1.32em;
  & span:nth-child(1) {
    line-height: 1.5;
  }
  & span:nth-child(2) {
    padding-inline-start: 8px;
  }
  & span:not(:nth-child(1)) {
    font-size: 1.62em;
    font-weight: bold;
    ${() =>
      media.sp(css`
        font-size: 1.32em;
      `)}
  }
  ${() =>
    media.sp(css`
      font-size: 1.12em;
    `)}
`
const StyledNumberWithRuby = styled.span`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  & rt {
    font-size: 0.75rem;
  }
`
const StyledReceptionInfo = styled.p`
  & span:nth-child(7) {
    paddding-inline: 4px;
    &::before {
      content: "※";
      padding-inline-start: 4px;
    }
  }
  & span:nth-child(8) {
    padding-inline-start: 2px;
  }
  ${() =>
    media.sp(css`
      font-size: 0.9em;
    `)}
`

const StyledCaution = styled.ul`
  font-size: 0.9em;
  list-style: initial;
  margin-left: 1.5rem;

  padding-inline-end: 1.5rem;
  width: 100%;
  & li {
    line-height: 1.4;
    word-break: break-word;
    overflow-wrap: break-word;
  }
`

export default function InquiryByPhone() {
  return (
    <StyledWrapper>
      <InquiryType title="お電話でのお問合せ" style={{ marginBottom: -16 }} />
      <StyledFreeDial>
        <a href="tel:0120894180">
          <span>フリーダイヤル</span>
          <span>0120</span>
          <span>-</span>
          <StyledNumberWithRuby>
            <rt>ヤクヨ</rt>
            <ruby>894</ruby>
          </StyledNumberWithRuby>
          <span>-</span>
          <StyledNumberWithRuby>
            <rt>イイパン</rt>
            <ruby>180</ruby>
          </StyledNumberWithRuby>
        </a>
      </StyledFreeDial>
      <StyledReceptionInfo>
        <span>【</span>
        <span>受付時間</span>
        <span>】 </span>
        <span>9:00</span>
        <span>-</span>
        <span>17:00</span>
        <span>年末年始を除く</span>
        <span>月</span>
        <span>〜</span>
        <span>土</span>
      </StyledReceptionInfo>
      <StyledCaution>
        <li>
          お問合せのお電話は、内容を正確に承るため録音をさせていただいております。
        </li>
        <li>
          <span>
            公衆電話、または番号非通知のお電話は承ることができません。
          </span>
          <span>番号通知が可能なお電話からおかけください。</span>
        </li>
      </StyledCaution>
    </StyledWrapper>
  )
}
