import React, { useContext, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import styled, { css } from "styled-components"
import { HeaderOverWrappedContext } from "../../hooks/HeaderOverWrappedContext"
import InquiryByPhone from "./InquiryByPhone"
import InquiryByEmail from "./InquiryByEmail"
import { media } from "../../styles/media"

const StyledWrapper = styled.div`
  // width: 80%;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  margin-inline: auto;
  align-items: flex-start;
  padding-block: 80px 20px;
  padding-inline: 20%;
  // background: ${({ theme }) => theme.colors.light.brown};
  ${() =>
    media.sp(css`
      padding-inline: 5%;
      overflow-wrap: anywhere;
    `)}
`

const StyledTitle = styled.h2`
  display: flex;
  color: ${({ theme }) => theme.colors.primary.brown};
  font-size: 1.62em;
  ${() =>
    media.sp(css`
      font-size: 1.32em;
    `)}
`

const StyledCopy = styled.p`
  padding-block-start: 0.62em;
  word-break: keep-all;
  line-height: 1.4;

  ${() =>
    media.sp(css`
      font-size: 0.9em;
    `)}
`

export default function InquiryContent() {
  const [state, setState] = useContext(HeaderOverWrappedContext)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 1,
  })

  useEffect(() => {
    setState(state => ({ ...state, isDisplay: !inView }))
  }, [inView])
  return (
    <StyledWrapper ref={ref}>
      <StyledTitle>商品に関するお問合せ</StyledTitle>
      <StyledCopy>
        フランソアは、お客さまからいただいた声を商品やサービスの改良に生かします。ぜひお声をお聞かせください。
      </StyledCopy>
      <InquiryByPhone />
      <InquiryByEmail />
    </StyledWrapper>
  )
}
