import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import axios from "axios"
import Recaptcha from "react-recaptcha"

import { media } from "../styles/media"
import { pagePath } from "../constants/link"

const Form = styled.form`
  padding: 30px 0px 50px;
  .formInput {
    border: 1px solid ${({ theme }) => theme.colors.light.brown};
    background: ${({ theme }) => theme.colors.light.brown};
    border-radius: 5px;
    padding: 50px 20px;
  }
  .phoneForm {
    input {
      width: 40% !important;
    }
  }
  .mailForm {
    input {
      width: 40% !important;
    }
  }
  .kanaForm,
  .nameForm {
    .seconds {
      width: 18% !important;
    }
  }
  ${() =>
    media.sp(css`
      padding: 0 0 50px;
      .formInput {
        padding: 20px 5px;
      }
      .phoneForm {
        input {
          width: 80% !important;
        }
      }
      .mailForm {
        input {
          width: 80% !important;
        }
      }
    `)}
`

const FormGroup = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  label {
    width: 25%;
    text-align: right;
    padding-right: 0.62em;
    color: #663100;
    font-size: 0.85em;
    font-weight: bold;
  }
  input[type="text"],
  input[type="phone"] {
    width: 20%;
    color: green;
    background: white;
    border: 1px solid #dadada;
    padding: 0.32em;
    transition: 0.3s;
    :focus {
      box-shadow: 1px 1px 1px brown;
    }
  }
  textarea {
    background: white;
    border: 1px solid #dadada;
    width: 70%;
    height: 100px;
    color: #008742;
    padding: 0.32em;
    transition: 0.3s;
    :focus {
      box-shadow: 1px 1px 1px brown;
    }
  }
  ${() =>
    media.sp(css`
      input[type="text"],
      input[type="phone"] {
        width: 30%;
      }
      label {
        width: 20%;
        font-size: 0.65em;
      }
      &.attachForm label {
        width: 100%;
        text-align: left;
        padding-bottom: 0.62em;
      }
      &.messageForm label {
        width: 100%;
        text-align: left;
        padding-bottom: 0.62em;
      }
      &.messageForm textarea {
        width: 100%;
      }
    `)}
`
const SubmitArea = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    p {
      width: 60%;
      padding: 50px 0 30px;
      font-size: 0.9em;
      a {
        color: ${theme.colors.primary.red};
        text-decoration: underline;
        padding: 0 0.32em;
        font-size: 0.9em;
      }
    }
    input[type="submit"] {
      padding: 0.62em 2.62em;
      border-radius: 20px;
    }
    ${() =>
      media.sp(css`
        margin-bottom: 30px;
        p {
          width: 100%;
        }
      `)}
  `
)

const Message = styled.p`
  color: ${props => props.theme.colors.primary.red};
  width: 50%;
  margin: 0 auto;
  font-size: 0.8em;
  padding: 0.62em 0;
`

const SubmitButton = styled.input`
  background: ${props => (props.disabled ? "gray" : "black")};
  color: white;
  padding: 0.32em 1.62em;
`

const StyledCompletionReportWrapper = styled.div`
  padding: 30px 0px 50px;
  & > div {
    margin-bottom: 2em;
    & > p {
      padding: 2px;
    }
  }
`

const StyledDisplayFormButtonWrapper = styled.div`
  margin-top: 2rem;
  text-align: center;
`

const StyledDisplayFormButton = styled.button`
  background: black;
  color: white;
  padding: 0.62em 2.62em;
  border-radius: 20px;
`

let inquiryNumber = undefined

const CompletionReport = ({ inquiryNumber, setIsSubmitted }) => (
  <StyledCompletionReportWrapper>
    <div>
      <p>
        受付番号&nbsp;{inquiryNumber ? "A" + inquiryNumber : ""}
        &nbsp;を受け付けました。
      </p>
      <p>この度はお問い合わせいただき、誠にありがとうございました。</p>
      <p>
        なお、ご記入いただいたメールアドレス宛てに、受け付け内容を自動送信させていただきました。
      </p>
      <p>
        お問い合わせの内容につきましては、確認でき次第対応させていただきます。
      </p>
      <p>今しばらくお待ちください。</p>
    </div>
    <div>
      <p>株式会社 フランソア</p>
      <p>福岡県粕屋郡新宮町緑ケ浜3丁目1番1号</p>
      <p>TEL　0120-894-180（フリーダイヤル）</p>
    </div>
    <StyledDisplayFormButtonWrapper
      onClick={() => {
        setIsSubmitted(false)
      }}
    >
      <StyledDisplayFormButton>
        お問い合わせフォームを表示する
      </StyledDisplayFormButton>
    </StyledDisplayFormButtonWrapper>
  </StyledCompletionReportWrapper>
)

const InquiryForm = ({ notify }) => {
  const inputRef = useRef({})
  const [message, setMessage] = useState({})
  const [attachedFiles, setAttachedFiles] = useState([])
  const [isValid, setIsValid] = useState({
    lastname: false,
    firstname: false,
    email: false,
    phone: false,
    content: false,
  })
  const [disabledState, setDisabledState] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const updatedMessage = {}
  const updatedIsValid = {}

  // useEffect(() => {
  //   inputRef.current["lastname"].focus()
  // }, [])

  useEffect(() => {
    updateDisabledState()
  }, [isValid])

  const updateDisabledState = () => {
    let state = false
    for (const key in isValid) {
      if (!isValid[key]) {
        state = true
        break
      }
    }
    setDisabledState(state)
  }

  const handleInput = e => {
    let val = e.target.value.trim()
    const id = e.target.id
    switch (id) {
      case "email":
        emailValidation(inputRef.current[id])
        break

      case "phone":
        const regex = /[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g
        phoneValidation(inputRef.current[id])
        val = e.target.value
          .replace(regex, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
          .replace(/[‐－―ー]/g, "-") // hyphen
          .replace(/[～〜]/g, "~") // tilde
          .replace(/　/g, " ") // space
        break

      case "lastname":
      case "firstname":
        nameValidation(inputRef.current[id])
        break
      case "rubyLastname":
      case "rubyFirstname":
        rubyValidation(inputRef.current[id])
      case "content":
        contentValidation(inputRef.current[id])
    }
    inputRef.current[id].value = val
  }

  const emailValidation = el => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    updatedIsValid[el.id] = false
    if (!el.value) {
      updatedMessage[el.id] = "メールアドレスを入力してください"
    } else if (!regex.test(el.value)) {
      updatedMessage[el.id] = "正しい形式のメールアドレスを入力してください"
    } else {
      updatedIsValid[el.id] = true
      updatedMessage[el.id] = ""
    }
    setIsValid(prev => ({ ...prev, ...updatedIsValid }))
    return setMessage(prevMessage => ({ ...prevMessage, ...updatedMessage }))
  }

  const phoneValidation = el => {
    const regex = /^0(\d{1,4}-\d{1,4}-\d{4}$|\d{9,10}$)/
    updatedIsValid[el.id] = false
    if (!el.value) {
      updatedMessage[el.id] = "電話番号を入力してください"
    } else if (!regex.test(el.value)) {
      updatedMessage[el.id] =
        "電話番号を入力してください。（使用できる文字は [ 0から9までの数字、-(ハイフン) ] です。"
    } else {
      updatedIsValid[el.id] = true
      updatedMessage[el.id] = ""
    }
    setIsValid(prev => ({ ...prev, ...updatedIsValid }))
    return setMessage(prevMessage => ({ ...prevMessage, ...updatedMessage }))
  }

  const nameValidation = el => {
    const regex = /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf\w()（）-]+$/

    updatedIsValid[el.id] = false

    if (!el.value) {
    } else if (!regex.test(el.value)) {
      updatedMessage[el.id] = "使用できない文字が含まれています"
    } else {
      updatedIsValid[el.id] = true
      updatedMessage[el.id] = ""
    }

    setIsValid(prev => ({ ...prev, ...updatedIsValid }))
    return setMessage(prevMessage => ({ ...prevMessage, ...updatedMessage }))
  }

  const rubyValidation = el => {
    const regex = /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf\w()（）-]+$/
    if (!regex.test(el.value)) {
      updatedMessage[el.id] = "使用できない文字が含まれています"
    } else {
      updatedMessage[el.id] = ""
    }
    return setMessage(prevMessage => ({ ...prevMessage, ...updatedMessage }))
  }

  const contentValidation = el => {
    updatedIsValid[el.id] = false
    if (!el.value) {
      updatedMessage[el.id] = "入力してください"
    } else {
      updatedIsValid[el.id] = true
      updatedMessage[el.id] = ""
    }
    setIsValid(prev => ({ ...prev, ...updatedIsValid }))
    // updateDisabledState()
    return setMessage(prevMessage => ({ ...prevMessage, ...updatedMessage }))
  }

  const handleFiles = e => {
    const validFileTypes = ["image/png", "image/jpeg", "image/heic"]
    const limit = {
      maxSizeInBytes: 4 * 1024 * 1024,
      maxFileCount: 3,
    }
    let fileSize = 0
    for (const file of e.target.files) {
      if (!validFileTypes.find(validFileType => validFileType === file.type)) {
        updatedMessage[e.target.id] =
          "添付可能なファイル形式は [jpeg, png, heic]のいずれかです"
        e.target.value = ""
        return setMessage(prevMessage => ({
          ...prevMessage,
          ...updatedMessage,
        }))
      }
      fileSize = fileSize + file.size
    }
    if (e.target.files.length > limit.maxFileCount) {
      updatedMessage[e.target.id] = "送信可能なファイルの数は３つまでです。"
      e.target.value = ""
    } else if (fileSize > limit.maxSizeInBytes) {
      updatedMessage[e.target.id] =
        "送信可能なファイルの合計サイズは4MBまでです。"
      e.target.value = ""
    } else {
      setAttachedFiles(e.target.files)
      updatedMessage[e.target.id] = ""
    }
    return setMessage(prevMessage => ({ ...prevMessage, ...updatedMessage }))
  }

  // Handle Recaptcha v3 & submitting form
  let recaptchaInstance
  const executeCaptcha = e => {
    e.preventDefault()
    recaptchaInstance.execute()
  }

  const verifyCallback = async response => {
    setDisabledState(true)
    handleSubmit(response)
  }

  const handleSubmit = async response => {
    const formData = new FormData(inputRef.current["submitForm"])
    formData.append("token", response)
    try {
      const result = await axios({
        method: "post",
        url: `${process.env.STRAPI_API_URL}/email-send`,
        data: formData,
      })

      const { data } = result
      process.env.NODE_ENV === "development" && console.log(result)

      // Display validation error message
      if (data.status === "validationError") {
        data.validationResults.forEach(res => {
          !res.isValid && notify.error(res.message)
        })
        return
      }

      // Display mail sending error
      if (data.status === "fail") return notify.error(data.message)

      // Display sending mail sending success
      if (data.corpRes.status === "success") {
        notify.success(data.corpRes.message)
        document.getElementById("submitForm").reset()
        inquiryNumber = data.corpRes.inquiryNumber

        setIsValid({
          lastname: false,
          firstname: false,
          email: false,
          phone: false,
          content: false,
        })

        setIsSubmitted(true)
      }
    } catch (err) {
      process.env.NODE_ENV === "development" && console.log(err)

      // if (err.message) return notify.error(err.message)
      // if (err["data"]["message"]) {
      //   return notify.error(err["data"]["message"])
      // }
      return notify.error(
        "送信時に何らかの不具合が発生したため、送信できなかった可能性があります。\
        お手数ですが時間を置いてから再度お試しください。"
      )
    }
  }

  // Render DOM
  if (isSubmitted) {
    return (
      <CompletionReport
        inquiryNumber={inquiryNumber}
        setIsSubmitted={setIsSubmitted}
      />
    )
  }
  return (
    <>
      <Form
        id="submitForm"
        ref={el => (inputRef.current["submitForm"] = el)}
        aria-label="Contact information"
        onSubmit={executeCaptcha}
        enctype="multipart/form-data"
      >
        <div className="formInput">
          <FormGroup className="nameForm">
            <label htmlFor="lastname">
              姓<span>&#42;</span>
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              placeholder="花立"
              required
              ref={el => (inputRef.current["lastname"] = el)}
              onBlur={handleInput}
            />
            {message.lastname && <Message>{message.lastname}</Message>}

            <label htmlFor="firstname" className="seconds">
              名<span>&#42;</span>
            </label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              placeholder="花子"
              required
              ref={el => (inputRef.current["firstname"] = el)}
              onBlur={handleInput}
            />
            {message.firstname && <Message>{message.firstname}</Message>}
          </FormGroup>

          <FormGroup className="kanaForm">
            <label htmlFor="rubyLastname">姓(ふりがな)</label>
            <input
              type="text"
              id="rubyLastname"
              name="rubyLastname"
              placeholder="はなたち"
              ref={el => (inputRef.current["rubyLastname"] = el)}
              onBlur={e => {
                inputRef.current["rubyLastname"].value && handleInput(e)
              }}
            />
            {message["rubyLastname"] && (
              <Message>{message["rubyLastname"]}</Message>
            )}

            <label htmlFor="rubyFirstname" className="seconds">
              名(ふりがな)
            </label>
            <input
              type="text"
              id="rubyFirstname"
              name="rubyFirstname"
              placeholder="はなこ"
              ref={el => (inputRef.current["rubyFirstname"] = el)}
              onBlur={e => {
                inputRef.current["rubyFirstname"].value && handleInput(e)
              }}
            />
            {message["rubyFirstname"] && (
              <Message>{message["rubyFirstname"]}</Message>
            )}
          </FormGroup>

          <FormGroup className="phoneForm">
            <label htmlFor="phone">
              お電話番号<span>&#42;</span>
            </label>
            <input
              type="phone"
              id="phone"
              name="phone"
              required
              ref={el => (inputRef.current["phone"] = el)}
              onBlur={handleInput}
            />
            {message.phone && <Message>{message.phone}</Message>}
          </FormGroup>

          <FormGroup className="mailForm">
            <label htmlFor="email">
              メールアドレス<span>&#42;</span>
            </label>
            <input
              type="text"
              id="email"
              name="email"
              required
              ref={el => (inputRef.current["email"] = el)}
              onBlur={handleInput}
            />
            {message.email && <Message>{message.email}</Message>}
          </FormGroup>

          <FormGroup className="messageForm">
            <label htmlFor="content">
              お問い合わせ内容<span>&#42;</span>
            </label>
            <textarea
              type="text"
              id="content"
              name="content"
              placeholder="どうぞお気軽に、ご意見などをお聞かせください"
              required
              ref={el => (inputRef.current["content"] = el)}
              onBlur={handleInput}
              onChange={handleInput}
            />
          </FormGroup>

          <FormGroup className="attachForm">
            <label>添付する画像を選択してください</label>
            <input type="file" id="files" name="files" multiple={true} />
            {message.files && <Message>{message.files}</Message>}
          </FormGroup>
        </div>

        <SubmitArea>
          <p>
            *は必須項目です。弊社の
            <Link to={pagePath.privacyPolicy.path}>
              {pagePath.privacyPolicy.text}
            </Link>
            をご確認いただき、同意のうえでご送信ください。
            <br />
            「送信する」をクリックすると、確認画面なしで送信されます。
          </p>
          <SubmitButton
            type="submit"
            value="送信する"
            disabled={disabledState}
            // onClick={() => updateDisabledState()}
          />
        </SubmitArea>
      </Form>

      <Recaptcha
        ref={e => (recaptchaInstance = e)}
        sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
        type="reCAPTCHA v3"
        size="invisible"
        verifyCallback={verifyCallback}
      />
    </>
  )
}

export default InquiryForm
